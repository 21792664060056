var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0010201" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            !_vm.isApprDisabled &&
                            _vm.showApproval &&
                            _vm.isOwner
                              ? _c("c-appr-btn", {
                                  ref: "appr-btn",
                                  attrs: {
                                    name: "danger-journal-appr-btn",
                                    editable: _vm.editable,
                                    approvalInfo: _vm.approvalInfo,
                                  },
                                  on: {
                                    beforeApprAction: _vm.saveDataAppr,
                                    callbackApprAction: _vm.approvalCallback,
                                    requestAfterAction: _vm.getDetail,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              disabled: true,
                              label: "LBL0010198",
                              name: "proceedingsTitle",
                            },
                            model: {
                              value: _vm.opinions.proceedingsTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.opinions, "proceedingsTitle", $$v)
                              },
                              expression: "opinions.proceedingsTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              disabled: true,
                              label: "LBL0010199",
                              name: "proceedingsDate",
                            },
                            model: {
                              value: _vm.opinions.proceedingsDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.opinions, "proceedingsDate", $$v)
                              },
                              expression: "opinions.proceedingsDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              disabled: true,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.opinions.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.opinions, "plantCd", $$v)
                              },
                              expression: "opinions.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-dept", {
                            attrs: {
                              required: true,
                              disabled: true,
                              editable: _vm.editable,
                              label: "작성부서",
                              name: "deptCd",
                            },
                            model: {
                              value: _vm.opinions.deptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.opinions, "deptCd", $$v)
                              },
                              expression: "opinions.deptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              disabled: true,
                              label: "의견접수 마감일",
                              name: "opinionDt",
                            },
                            model: {
                              value: _vm.opinions.opinionDt,
                              callback: function ($$v) {
                                _vm.$set(_vm.opinions, "opinionDt", $$v)
                              },
                              expression: "opinions.opinionDt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              rows: 2,
                              label: "LBL0010202",
                              name: "proceedingsContents",
                            },
                            model: {
                              value: _vm.opinions.proceedingsContents,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.opinions,
                                  "proceedingsContents",
                                  $$v
                                )
                              },
                              expression: "opinions.proceedingsContents",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-table", {
                            ref: "table",
                            attrs: {
                              title: "참석자 의견",
                              columns: _vm.grid.columns,
                              data: _vm.opinions.opinionlist,
                              gridHeight: _vm.gridHeight,
                              merge: _vm.grid.merge,
                              editable:
                                _vm.editable && !_vm.disabled && !_vm.isOwner,
                              isExcelDown: false,
                              filtering: false,
                              columnSetting: false,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }